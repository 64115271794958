const dictionary = {
  resident: {
    'fr-FR': {
      singular: 'Résident',
      plural: 'Résidents',
    },
    'en-GB': {
      singular: 'Resident',
      plural: 'Residents',
    },
    'de-DE': {
      singular: 'Einwohner',
      plural: 'Bewohner',
    },
  },
  carrier: {
    'fr-FR': {
      singular: 'Transporteur',
      plural: 'Transporteurs',
    },
    'en-GB': {
      singular: 'Carrier',
      plural: 'Carriers',
    },
    'de-DE': {
      singular: 'Träger',
      plural: 'Spediteur',
    },
  },
  service: {
    'fr-FR': {
      singular: 'Service',
      plural: 'Services',
    },
    'en-GB': {
      singular: 'Service',
      plural: 'Services',
    },
    'de-DE': {
      singular: 'Service',
      plural: 'Service',
    },
  },
}

export default dictionary
