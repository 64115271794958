import notification from 'antd/es/notification'
import { io } from 'socket.io-client'

const notificationKey = 'socketStatus'
const socket = io(process.env.REACT_APP_CORELAYER_API, {
  auth: { token: null },
  transports: ['websocket'],
  autoConnect: false,
})
let started = false
socket.on('connect', () => {
  console.info('[websocket] Connected to server')
  if (started) {
    notification.destroy(notificationKey)
  }
})
socket.on('disconnect', () => {
  console.warn('[websocket] Disconnected from server')
  // if (started) {
  //   notification.warning({
  //     key: notificationKey,
  //     message: 'Server connection lost',
  //     description:
  //       'The connection between this client and the server has been interrupted.\nWe are trying to reconnect as soon as possible.',
  //     duration: 0,
  //   })
  // }
})

/**
 * Connect web socket to server.
 *
 * @param {string} token Authentication token
 */
export function connectSocket(token) {
  if (socket.auth.token !== token || !socket.connected) {
    if (socket.connected) {
      socket.disconnect()
    }
    socket.auth.token = token
    socket.connect()
    started = true
  }
}

/**
 * Disconnect the web socket from the server.
 */
export function disconnectSocket() {
  started = false
  socket.disconnect()
}

export default socket
