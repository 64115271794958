import Button from 'antd/es/button'
import Result from 'antd/es/result'
import React from 'react'
import { FormattedMessage as FM } from 'react-intl'

export default class TopLevelErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null,
    }
  }

  reset() {
    this.setState({
      hasError: false,
      error: null,
      info: null,
    })
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info,
    })
  }

  render() {
    if (this.state.hasError) {
      console.warn(this.state)
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <Button type="primary" onClick={this.reset}>
                <FM id="back.home" defaultMessage="Back home" />
              </Button>
            }
          />
        </div>
      )
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children
  }
}
