import React, { memo } from 'react'

const IMAGE_WIDTH = 96
const style = {
  width: IMAGE_WIDTH,
  height: IMAGE_WIDTH,
  transform: `translate(-${IMAGE_WIDTH / 2}px, -${IMAGE_WIDTH / 2}px)`,
}

export default memo(() => <img src={process.env.REACT_APP_LOGO_SQUARE} className="loading__img" style={style} alt="" />)
