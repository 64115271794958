import ConfigProvider from 'antd/es/config-provider'
import deDE from 'antd/es/locale/de_DE'
import enUS from 'antd/es/locale/en_US'
import frFR from 'antd/es/locale/fr_FR'
import PropTypes from 'prop-types'
import React, { memo, createContext, useContext, useState, useCallback, useEffect } from 'react'
import { injectIntl, IntlProvider } from 'react-intl'

import defaultGeneralDictionary from '@assets/locales/defaultDictionary.js'
import { getLanguageCodeFromNavigator } from '@assets/locales/LanguageList.js'
import messagesDe from '@assets/locales/translations/de.json'
import messagesEn from '@assets/locales/translations/en.json'
import messagesFr from '@assets/locales/translations/fr.json'
import AuthContext from '@contexts/AuthContext.jsx'

/**
 * Language context.
 */
const LanguageContext = createContext({ language: 'en-US' })
export default LanguageContext

const LanguageContextProvider = ({
  children,
  language,
  changeLanguage,
  defaultDictionary,
  generateDefaultDictionary,
}) => (
  <LanguageContext.Provider value={{ language, changeLanguage, defaultDictionary, generateDefaultDictionary }}>
    {children}
  </LanguageContext.Provider>
)

LanguageContextProvider.propTypes = {
  defaultDictionary: PropTypes.object.isRequired,
  generateDefaultDictionary: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
}

/**
 * React-Intl context wrapper.
 * Provides language tools.
 */

const jsonMessages = {
  'fr-FR': messagesFr,
  'en-GB': messagesEn,
  'en-US': messagesEn,
  'de-DE': messagesDe,
}

const antdLangInternal = {
  'fr-FR': frFR,
  'en-GB': enUS,
  'en-US': enUS,
  'de-DE': deDE,
}
const navigatorLanguage = getLanguageCodeFromNavigator(navigator.language)

const LanguageContextProviderWrapper = injectIntl(LanguageContextProvider)

export const LanguageContextWrapper = memo(({ children }) => {
  const { user, updateUser } = useContext(AuthContext)
  const [language, setLanguage] = useState(navigatorLanguage)
  const [antdLanguage, setAntdLanguage] = useState(antdLangInternal[navigatorLanguage] || enUS)
  const [messages, setMessages] = useState(jsonMessages[navigatorLanguage] || jsonMessages['en-US'])

  /**
   *
   * @param {string} lang
   */
  function changeLanguage(lang) {
    setLanguage(lang)
    setMessages(jsonMessages[lang] || jsonMessages['en-US'])
    setAntdLanguage(antdLangInternal[lang])
    if (user) {
      updateUser({ profile: { ...user.profile, language: lang } })
    }
  }

  /**
   *
   * @param {object} dictionary
   * @param {string} name
   * @param {boolean} plural
   * @param {boolean} capitalize
   * @returns {string}
   */
  function getNameFromDictionary(name, dictionary = defaultGeneralDictionary, plural = false, capitalize = false) {
    const singularOrPlural = plural ? 'plural' : 'singular'
    let res = dictionary?.[name]?.[language]?.[singularOrPlural] ?? name

    if (capitalize) {
      res = res.charAt(0).toUpperCase() + res.slice(1)
    }

    return res
  }

  /**
   *
   * @param {object} dictionary
   * @returns {object}
   */
  function generateDefaultDictionary(dictionary = defaultGeneralDictionary) {
    const values = {}

    values.Carriers = getNameFromDictionary('carrier', dictionary, true, true)
    values.carriers = getNameFromDictionary('carrier', dictionary, true, false)
    values.Carrier = getNameFromDictionary('carrier', dictionary, false, true)
    values.carrier = getNameFromDictionary('carrier', dictionary, false, false)
    values.Residents = getNameFromDictionary('resident', dictionary, true, true)
    values.residents = getNameFromDictionary('resident', dictionary, true, false)
    values.Resident = getNameFromDictionary('resident', dictionary, false, true)
    values.resident = getNameFromDictionary('resident', dictionary, false, false)
    values.Services = getNameFromDictionary('service', dictionary, true, true)
    values.services = getNameFromDictionary('service', dictionary, true, false)
    values.Service = getNameFromDictionary('service', dictionary, false, true)
    values.service = getNameFromDictionary('service', dictionary, false, false)

    return values
  }

  const defaultDictionary = generateDefaultDictionary()

  if (user?.profile?.language && user?.profile?.language !== language) {
    changeLanguage(user.profile.language)
  }

  return (
    <IntlProvider locale={language} messages={messages}>
      <LanguageContextProviderWrapper
        defaultDictionary={defaultDictionary}
        generateDefaultDictionary={generateDefaultDictionary}
        language={language}
        changeLanguage={changeLanguage}
      >
        <ConfigProvider locale={antdLanguage}>{children}</ConfigProvider>
      </LanguageContextProviderWrapper>
    </IntlProvider>
  )
})

LanguageContextWrapper.propTypes = { children: PropTypes.object.isRequired }
